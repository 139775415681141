import React from "react";
import { Outlet } from "react-router-dom";
import SideNav from "../Components/SideNav";
import rootStyle from "./RootLayout.module.css";
import TopNav from "../Components/TopNav";

function RootLayout() {
  return (
    <div className={rootStyle.bodyContainer}>
      <TopNav />
      <SideNav />
      <Outlet />
    </div>
  );
}

export default RootLayout;
