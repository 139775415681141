import aboutStyle from "./AboutPage.module.css";
import { userProfile } from "../Components/UserProfile/UserData";


function AboutPage(props) {


  return (
    <div className={aboutStyle.aboutbody} id={props.id}>
      <div className={aboutStyle.majorContainer}>
        {/* TOP */}
        <div className={aboutStyle.topContainer}>
          <div className={aboutStyle.topTextContainer}>
            {" "}
            <p>
              <b className={aboutStyle.titleContainer}>
                About
              </b>{" "}
              {userProfile.short_desc}
            </p>
          </div>
        </div>

        {/* MIDDLE */}
        <div className={aboutStyle.midContainer}>
          <div className={aboutStyle.outerContainer}>
            <ul>
              {userProfile.userData.slice(0, 4).map((data) => (
                <li>
                  <div className={aboutStyle.innerContainer}>
                    <p className={aboutStyle.title}>{data.title}: </p>
                    <p className={aboutStyle.data}>{data.data}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className={aboutStyle.outerContainer}>
            <ul>
              {userProfile.userData.slice(4, 8).map((data) => (
                <li>
                  <div className={aboutStyle.innerContainer}>
                    <p className={aboutStyle.title}>{data.title}: </p>
                    <p className={aboutStyle.data}>{data.data}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* BOTTOM */}
        <div className={aboutStyle.bottomContainer}>
          {userProfile.long_desc}
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
