import "./App.css";
// import SideNav from "./Components/SideNav";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RootLayout from "./Pages/RootLayout";
// import HomePage from "./Pages/HomePage";
// import AboutPage from "./Pages/AboutPage";
// import ServicesPage from "./Pages/ServicesPage";
// import ExperiencesPage from "./Pages/ExperiencesPage";
// import PortfolioPage from "./Pages/PortfolioPage";
// import ContactPage from "./Pages/ContactPage";
// import BlogPage from "./Pages/BlogPage";
import ErrorPage from "./Pages/ErrorPage";
import IndexPage from "./Pages/IndexPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      // NOTE: If a path starts with a slash (/), then it is an absolute path
      // { path: "/", element: <HomePage /> },
      { index: true, element: <IndexPage /> },
      // { path: LINKS.about, element: <AboutPage /> },
      // { path: LINKS.services, element: <ServicesPage /> },
      // { path: LINKS.experiences, element: <ExperiencesPage /> },
      // { path: LINKS.portfolio, element: <PortfolioPage /> },
      // This path is to be used in the future when I am to use portfolio details
      // const params = useParams
      // params.id
      // { path: "/portfolio/:id", element: <PortfolioDetailPage /> },
      // { path: LINKS.articles, element: <BlogPage /> },
      // { path: LINKS.contact, element: <ContactPage /> },
      // NOTE: <Link to=".." relative="path"></Link>
      // Without the relative path, If you click the link it take you back to the root page.
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
