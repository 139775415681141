import React from "react";
import copyStyle from "./Copyright.module.css";

function Copyright() {
    const currentYear = new Date().getFullYear();
  return (
    <div className={copyStyle.copyrightContainer}>
      &copy; {currentYear} Adedoyin Idris
    </div>
  );
}

export default Copyright;
