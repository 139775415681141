import servicesStyle from "./ServicesPage.module.css";
import { userProfile } from "../Components/UserProfile/UserData";


function ServicesPage(props) {

  return (
    <div className={servicesStyle.serviceBody} id={props.id}>
      <div className={servicesStyle.container}>
        <div>
          <p className={servicesStyle.title}>Services</p>
        </div>
        <div className={servicesStyle.serviceContainer}>
          {userProfile.services.map((data) => (
            <div className={servicesStyle.inServiceContainer}>
              <img src={data.img} alt="Skills" />
              <h2>{data.title}</h2>
            </div>
          ))}
        </div>
        <div className={servicesStyle.skills}>
          <h2>Skills</h2>
        </div>
        <div className={servicesStyle.skillsContainer}>
          {userProfile.skills.map((data) => (
            <div className={servicesStyle.skillsWrapper}>
              <p>{data}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ServicesPage;
