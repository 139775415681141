import React from "react";
import articleStyle from "./BlogPage.module.css";
import { userProfile } from "../Components/UserProfile/UserData";

function BlogPage(props) {
  return (
    <div className={articleStyle.articleBody} id={props.id}>
      <div className={articleStyle.container}>
        <h2 className={articleStyle.pageTitle}>Articles</h2>
        <div className={articleStyle.pageDesc}>
          <p className={articleStyle.pageDescText}>{userProfile.articleDesc}</p>
        </div>
        <div className={articleStyle.gridContainer}>
          {userProfile.articleList
            .sort((a, b) => {
              let fa = a.name.toLowerCase(),
                fb = b.name.toLowerCase();

              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            })
            .map((data) => (
              <div className={articleStyle.gridItemContainer}>
                <img src={data.image} alt="" />
                <p className={articleStyle.linksContainer}>
                  <a href={data.link} className={articleStyle.links}>
                    Read Article
                  </a>
                </p>
                <p className={articleStyle.name}>{data.name}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default BlogPage;
