import React from "react";
import HomePage from "./HomePage";
import AboutPage from "./AboutPage";
import ServicesPage from "./ServicesPage";
import PortfolioPage from "./PortfolioPage";
import BlogPage from "./BlogPage";
import ContactPage from "./ContactPage";
import ExperiencesPage from "./ExperiencesPage";
import indexStyle from "./IndexPage.module.css";
import { LINKS } from "../Generics/Links";

function Index() {
  return (
    <div className={indexStyle.indexBody}>
      <HomePage id={LINKS.home} />
      <AboutPage id={LINKS.about} />
      <ServicesPage id={LINKS.services} />
      <ExperiencesPage id={LINKS.experiences} />
      <PortfolioPage id={LINKS.portfolio} />
      <BlogPage id={LINKS.articles} />
      <ContactPage id={LINKS.contact} />
    </div>
  );
}

export default Index;
