import React from "react";

import { HiOutlineHome } from "react-icons/hi";
import { BsInfoCircle } from "react-icons/bs";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { ImProfile } from "react-icons/im";
import { MdOutlineHistory } from "react-icons/md";
import { SlNotebook } from "react-icons/sl";
import { MdOutlineEmail } from "react-icons/md";
import { LINKS } from "../Generics/Links";

// import InfoIcon from "@material-ui/icons/Info";

export const SidebarData = [
  {
    title: "Home",
    icon: <HiOutlineHome />,
    link: LINKS.home,
  },
  {
    title: "About",
    icon: <BsInfoCircle />,
    link: LINKS.about,
  },
  {
    title: "Services",
    icon: <MdOutlineMiscellaneousServices />,
    link: LINKS.services,
  },
  {
    title: "Experiences",
    icon: <ImProfile />,
    link: LINKS.experiences,
  },
  {
    title: "Portfolio",
    icon: <MdOutlineHistory />,
    link: LINKS.portfolio,
  },
  {
    title: "Articles",
    icon: <SlNotebook />,
    link: LINKS.articles,
  },
  {
    title: "Contact",
    icon: <MdOutlineEmail />,
    link: LINKS.contact,
  },
];
