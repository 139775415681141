import React from "react";
import errorStyle from './ErrorPage.module.css'


function ErrorPage() {
  return (
    <div className={errorStyle.body}>
      <div className={errorStyle.card}>
        <h2>Sorry, Page not found.</h2>
      </div>
    </div>
  );
}

export default ErrorPage;
