import React from "react";
import topSidebarStyle from "./TopSidebar.module.css";
import { userProfile } from "./UserProfile/UserData";
import userPicture from "../images/adedoyin.jpeg";
import { NavLink } from "react-router-dom";

function TopSidebar() {
  return (
    <div className={topSidebarStyle.container}>
      <div className={topSidebarStyle.imageContainer}>
        <img src={userPicture} className={topSidebarStyle.image} alt="" />
      </div>
      <h2 id={topSidebarStyle.username}>{userProfile.username}</h2>
      {/* <div className={topSidebarStyle.profileUrlContainer}> */}
      <ul className={topSidebarStyle.profileIconList}>
        {userProfile.profileLinks.map((data) => {
          return (
            <li key={data.name} className={topSidebarStyle.row}>
              <NavLink
                to={data.link}
                style={({ isActive }) => ({
                  color: isActive ? "#E1D9D1" : "#E1D9D1",
                })}
              >
                {data.icon}
              </NavLink>
            </li>
          );
        })}
      </ul>
      {/* </div> */}
    </div>
  );
}

export default TopSidebar;
