import React from "react";
import contactStyle from "./ContactPage.module.css";
import Copyright from "../Components/Copyright";

function ContactPage(props) {
  return (
    <div className={contactStyle.contactBody} id={props.id}>
      <div className={contactStyle.container}>
        <h2 className={contactStyle.pageTitle}>Contact</h2>
        <div className={contactStyle.helloContainer}>
          <p>
            <p className={contactStyle.hello}>Hello there!</p> Thank you for
            visiting my portfolio. Feel free to reach out to me for any
            inquiries or opportunities. Looking forward to connecting with you!
          </p>
        </div>
        <div className={contactStyle.nameandemail}>
          <input className={contactStyle.contactName} placeholder="Fullname" />
          <input
            className={contactStyle.contactEmail}
            placeholder="Email address"
          />
        </div>
        <div className={contactStyle.subjectContainer}>
          <input className={contactStyle.subject} placeholder="Subject" />
        </div>
        <div className={contactStyle.messageContainer}>
          <textarea
            className={contactStyle.message}
            placeholder="Enter your message here..."
          />
        </div>
        <div className={contactStyle.buttonContainer}>
          <button className={contactStyle.button} disabled>
            Currently Disabled
          </button>
        </div>
        {window.innerWidth < 800 ? <Copyright /> : ""}
      </div>
    </div>
  );
}

export default ContactPage;
