import expStyle from "./ExperiencesPage.module.css";
import { userProfile } from "../Components/UserProfile/UserData";
import { NavLink } from "react-router-dom";

function ExperiencesPage(props) {

  return (
    <div className={expStyle.experienceBody} id={props.id}>
      <div className={expStyle.container}>
        <h2 className={expStyle.pageTitle}>Experiences</h2>
        {userProfile.experiences.map((data) => (
          <div className={expStyle.descContainer}>
            <p className={expStyle.descTitle}>{data.title}</p>
            <p className={expStyle.company}>{data.company}</p>
            <p className={expStyle.date}>
              {data.date} · {data.length}
            </p>
            <ul>
              {data.description.map((desc) => (
                <li>○ {desc}</li>
              ))}
            </ul>
          </div>
        ))}
        <div className={expStyle.moreContainer}>
          <div className={expStyle.seeMore}>
            <NavLink
              to={userProfile.profileLinks[1].link}
              style={{ textDecoration: "none" }}
            >
              <p className={expStyle.seeMoreText}>See more</p>
            </NavLink>
          </div>
          {/* ADD DOWNLOAD RESUME IN THE FUTURE */}
          {/* <div>
            <p></p>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ExperiencesPage;
