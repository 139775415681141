import React, { useEffect, useState } from "react";
import portStyle from "./PortfolioPage.module.css";
import { userProfile } from "../Components/UserProfile/UserData";
// import { Button } from "@material-ui/core";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import android from "../images/android.jpg";
import ios from "../images/ios.jpg";

function PortfolioPage(props) {
  const [isActive, setIsActive] = useState(true);
  const [currentId, setId] = useState(0);
  const [showOverlay, setShowOverlay] = useState(false);
  // const id = useRef(0);
  const toggle = (cats) => {
    console.log("CATS");
    console.log(cats);
    setId(() => cats);
    setIsActive(() => (!isActive ? true : false));
  };

  // useEffect(() => id.current = currentId, []);

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  };

  function catsItemData(catsItem) {
    if (catsItem.category && currentId === 0) {
      return catsItem;
    } else if (catsItem.category === "mobile" && currentId === 1) {
      return catsItem;
    } else if (catsItem.category === "web" && currentId === 2) {
      return catsItem;
    } else if (catsItem.category === "dsc" && currentId === 3) {
      return catsItem;
    }
  }

  // const width = window.innerWidth;
  // const height = window.innerHeight;
  // const [size, setSize] = useState(window.innerWidth);
  // let chWidth = size;

  // useEffect(
  //   () => setSize(() => (chWidth = window.innerWidth)),
  //   [window.innerWidth]
  // );

  return (
    <div className={portStyle.portBody} id={props.id}>
      <div className={portStyle.container}>
        <h2 className={portStyle.pageTitle}>Portfolio</h2>
        <div className={portStyle.descContainer}>
          <p className={portStyle.desc}>{userProfile.portfolio.short_desc}</p>
        </div>
        <div className={portStyle.catsLabelContainer}>
          {userProfile.portfolio.cats.map((cats) => (
            <div
              onClick={() => toggle(userProfile.portfolio.cats.indexOf(cats))}
              className={
                currentId === userProfile.portfolio.cats.indexOf(cats)
                  ? portStyle.catsLabelActive
                  : portStyle.catsLabelInactive
              }
            >
              {cats}
            </div>
          ))}
        </div>
        <div className={portStyle.dataOuterContainer}>
          {userProfile.portfolio.projects
            .sort((a, b) => {
              let fa = a.name.toLowerCase(),
                fb = b.name.toLowerCase();

              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            })
            .filter(catsItemData)
            .map((data) => (
              <Popup
                trigger={
                  <div
                    className={portStyle.dataItemContainer}
                    // style={{ backgroundImage: `url(${data.displayImage})`,}}
                    onClick={toggleOverlay}
                  >
                    <img src={data.displayImage} alt="" />
                    <p>{data.name}</p>
                  </div>
                }
                modal
                nested
              >
                <div className="popup">
                  {data.category === "mobile" ? (
                    <div className={portStyle.mobileContainer}>
                      <div className={portStyle.displayImageContainer}>
                        <img
                          src={data.displayImage}
                          alt=""
                          className={portStyle.mobileImage}
                        />
                      </div>
                      <div className={portStyle.mobLinkImgContainer}>
                        <a href={data.android}>
                          {" "}
                          <img
                            src={android}
                            alt=""
                            className={portStyle.android}
                          />
                        </a>
                        {data.hasIos ? (
                          <a href={data.ios}>
                            {" "}
                            <img src={ios} alt="" className={portStyle.ios} />
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <p className={portStyle.appTitle}>{data.name}</p> */}
                    </div>
                  ) : (
                    <div className={portStyle.nonMobContainer}>
                      <img
                        src={data.displayImage}
                        alt=""
                        className={portStyle.nonMobileImage}
                      />
                      <a href={data.isWeb ? data.web : data.dsc}>
                        {data.isWeb ? "Visit Website" : "View Source"}
                      </a>
                    </div>
                  )}
                </div>
              </Popup>
            ))}
        </div>
      </div>
    </div>
  );
}

export default PortfolioPage;
