import React from "react";
import sideNavStyle from "./SideNav.module.css";
import { SidebarData } from "./SidebarData";
import TopSidebar from "./TopSidebar";
import Copyright from "./Copyright";
import { Link } from "react-scroll";

function SideNav() {
  return (
    <div className={sideNavStyle.navBody}>
      <TopSidebar />
      <ul className={sideNavStyle.sidebarList}>
        {SidebarData.map((value, key) => {
          return (
            <li
              key={key}
              // id={
              //   window.location.pathname === value.link
              //     ? sideNavStyle.active
              //     : ""
              // }
              className={sideNavStyle.row}
            >
              <Link
                activeClass={sideNavStyle.active}
                spy={true}
                smooth={true}
                // offset={-70}
                duration={500}
                to={value.link}
                id={sideNavStyle.inactive}
                // className={({ isActive }) =>
                //   !isActive ? sideNavStyle.active : sideNavStyle.inactive
                // }
                end={value.link === "/" ? true : false}
              >
                <div id={sideNavStyle.icon}> {value.icon}</div>
                <div id={sideNavStyle.title}>{value.title}</div>
              </Link>
            </li>
          );
        })}
      </ul>
      <Copyright />
    </div>
  );
}

export default SideNav;
