import React from "react";
import topNavStyle from "./TopNav.module.css";
import userPicture from "../images/adedoyin.jpeg";
import { userProfile } from "./UserProfile/UserData";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
import { SidebarData } from "./SidebarData";

function TopNav() {
  return (
    <div className={topNavStyle.navBody}>
      <div className={topNavStyle.leftContainer}>
        <div className={topNavStyle.nameProfileContainer}>
          {/* <h2 className={topNavStyle.port}>My Portfolio</h2> */}
          <div className={topNavStyle.imageContainer}>
            <img src={userPicture} className={topNavStyle.image} alt="" />
          </div>

          <ul className={topNavStyle.profileIconList}>
            {userProfile.profileLinks.map((data) => {
              return (
                <li key={data.name} className={topNavStyle.row}>
                  <NavLink
                    to={data.link}
                    style={({ isActive }) => ({
                      color: isActive ? "#E1D9D1" : "#E1D9D1",
                    })}
                  >
                    {data.icon}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className={topNavStyle.rightContainer}>
        <ul className={topNavStyle.navList}>
          {SidebarData.map((value, key) => {
            return (
              <li
                key={key}
                // id={
                //   window.location.pathname === value.link
                //     ? sideNavStyle.active
                //     : ""
                // }
                id={topNavStyle.row}
              >
                <Link
                  activeClass={topNavStyle.active}
                  spy={true}
                  smooth={true}
                  // offset={-70}
                  duration={500}
                  to={value.link}
                  id={topNavStyle.inactive}
                  // className={({ isActive }) =>
                  //   !isActive ? sideNavStyle.active : sideNavStyle.inactive
                  // }
                  end={value.link === "/" ? true : false}
                >
                  {/* <div id={topNavStyle.icon}> {value.icon}</div> */}
                  <div id={topNavStyle.title}>{value.title}</div>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default TopNav;
